import { Autocomplete, Button, FormControl, Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import * as Redux from 'react-redux';
// import { saveCodigosAction } from '../CodigosAction';
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as yup from 'yup';
import LoadingComponent from '../../../components/Loading';
import { SU } from '../../../constants/ConfigConst';
import { useNotification } from '../../../helpers/notification';
import { getAllAreasService } from '../../areas/AreasService';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { getAllSubAreasService } from '../../subareas/SubAreasService';
import { getAllUsuariosCodigosService } from '../../userCodes/UserCodesService';
import { saveReservasService } from '../ReservasService';
import ReservasHours from './ReservaHours';
import FormUser from '../../usuarios/components/FormUser';
import AddIcon  from '@mui/icons-material/Add';
import { Modal } from '../../../components';
import { getDateTimezone } from '../../../helpers';

const FormReserva = ({ setOpenModalForm, params, onRefresh }) => {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [userCodes, setUserCodes] = React.useState([]);
  const [areas, setAreas] = React.useState([]);
  const [subareas, setSubareas] = React.useState([]);
  const [currentReservations, setCurrentReservations] = React.useState([]);
  const loginStore = Redux.useSelector((state) => state.login);
  const [modalFormUser, setModalFormUser] = React.useState(false);
  const [userCodeValue, setUserCodeValue] = React.useState(null);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const timezone = entidadesStore.activo.country.timezone;
  const utcOffset = entidadesStore.activo.country.utcOffset || 'UTC-04:00';

  const onSubmit = async (values) => {
    const authUserCode = loginStore.user.userCodes.find((el) => {
      return (
        (el.code.codeType === 'admin' &&
          entidadesStore.activo.id === el.code.entity.id) ||
        loginStore.user.roles.some((r) => r.name === SU)
      );
    });
    const dto = {
      subareaId: values.subareaId,
      userCodeId: values.userCodeId,
      reservations: values.reservations,
      requestOrigin: 'web',
      createdById: authUserCode?.id,
    };

    setLoading(true);
    try {
      const { data } = await saveReservasService(dto);
      onRefresh();
      setOpenModalForm(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response.data.message);
        addNotification(error.response.data.message, { error: true });
      }
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    userCodeId: yup.number().integer().required('El usuario es requerido'),
    areaId: yup.number().integer().required('El área es requerida'),
    subareaId: yup.number().integer().required('La subárea es requerida'),
    date: yup
      .date()
      .required('La fecha es requerida')
      .min(
        new Date(dayjs().utcOffset(utcOffset).startOf('day').format()),
        'La fecha no puede ser menor a hoy'
      ),
    reservations: yup
      .array()
      .of(
        yup.object().shape({
          departureTime: yup.date().required(),
          entryTime: yup.date().required(),
        })
      )
      .required('Las reservas son requeridas')
      .min(1, 'Debe seleccionar al menos un horario')
      .max(4, 'No puede seleccionar más de 4 horarios')
      .test(
        'validate-continuous-reservations',
        'Los horarios deben ser continuos',
        (value) => {
          if (!value) return false;
          const sortedReservations = value.sort(
            (a, b) => a.entryTime - b.entryTime
          );
          for (let i = 0; i < sortedReservations.length - 1; i++) {
            if (
              sortedReservations[i].departureTime !==
              sortedReservations[i + 1].entryTime
            ) {
              return false;
            }
          }
          return true;
        }
      ),
  });

  const initialValue = {
    reservations: [],
    areaId: null,
    subareaId: null,
    userCodeId: null,
    date: new Date(dayjs().utcOffset(utcOffset).startOf('day').format()),
  };

  const getUserCodes = async (params = {}) => {
    const res = await getAllUsuariosCodigosService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const userCodes = data.map((el) => ({
      id: el.id,
      name: `${el.user.profile.firstName} ${el.user.profile.lastName}`,
      avatar: el.avatar || el.user.profile.avatar || null,
      email: el.email,
      documentId: `${el.user.profile.typeDocument}-${el.user.profile.identificationNumber}`,
      label: `(${el.code.code}) - ${el.user.profile.firstName} ${el.user.profile.lastName}`,
    }));
    if (res) setUserCodes(userCodes);
  };

  // const getAreas = async (params = {}) => {
  const getSubareas = async (params = {}) => {
    const res = await getAllSubAreasService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const subareas = data.map((el) => ({
      id: el.id,
      name: el.name,
      imageUrl: el.imageUrl,
      label: `${el.name}${el.price ? ' ($)' : ''}`,
      dates: el.dates,
      openingTime: el.openingTime,
      closingTime: el.closingTime,
      interval: el.interval,
      areaId: el.areaId,
      price: el.price,
    }));
    if (res) setSubareas(subareas);
  };

  const getAreas = async (params = {}) => {
    const res = await getAllAreasService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const areas = data.map((el) => ({
      id: el.id,
      name: el.name,
      imageUrl: el.imageUrl,
      label: el.name,
      openingTime: el.openingTime,
      closingTime: el.closingTime,
      blockReservation: el.blockReservation,
    }));
    if (res) setAreas(areas);
  };
  const findUserCode = (id) => userCodes.find((el) => el.id === id);
  const findArea = (id) => areas.find((el) => el.id === id);
  const findSubarea = (id) => subareas.find((el) => el.id === id);

  React.useEffect(() => {
    getUserCodes();
    getSubareas();
    getAreas();
  }, []);

  return (
    <Box component="form" sx={{}}>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {({ values, setFieldValue, handleSubmit, errors, validateField }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <LoadingComponent isLoading={loading} text={'Guardando...'} />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                <Autocomplete
                  fullWidth
                  disableClearable
                  id="combo-box-usuario"
                  options={userCodes}
                  size="small"
                  value={userCodeValue}
                  style={{ flexGrow: 1 }}
                  renderInput={(params) => {
                    return (
                      <Grid>
                        <TextField
                          {...params}
                          label="Usuario"
                          placeholder="Buscar usuario"
                          onBlur={() => {
                            validateField('userCodeId');
                          }}
                          helperText={errors.userCodeId || null}
                          error={!!errors.userCodeId}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: values.userCodeId ? (
                              <img
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                  borderRadius: '50%',
                                  objectFit: 'cover',
                                  display: 'block',
                                }}
                                src={findUserCode(values.userCodeId)?.avatar}
                                alt={findUserCode(values.userCodeId)?.label}
                              />
                            ) : null,
                          }}
                        />
                      </Grid>
                    );
                  }}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <img
                          style={{
                            width: 50,
                            height: 50,
                            marginRight: 10,
                            borderRadius: '50%',
                            objectFit: 'cover',
                            display: 'block',
                          }}
                          src={option.avatar}
                          alt={option.label}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  key={(option) => option.id}
                  onChange={(e, value) => {
                    setUserCodeValue(value);
                    setFieldValue('userCodeId', value?.id || null);
                    setTimeout(() => {
                      validateField('userCodeId');
                    });
                  }}
                />
                {
                  entidadesStore.activo.isPublic && (
                    <Button 
                      variant="contained" 
                      color="primary" 
                      size="small" 
                      style={{ width: 40, height: 40, marginLeft: 10 }}
                      onClick={() => setModalFormUser(true)}>
                      <AddIcon />
                    </Button>
                  )
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  disableClearable
                  id="combo-box-demo"
                  options={areas}
                  size="small"
                  renderInput={(params) => {
                    return (
                      <Grid>
                        <TextField
                          {...params}
                          label="Área"
                          placeholder="Buscar área"
                          onBlur={() => {
                            validateField('areaId');
                          }}
                          helperText={errors.areaId || null}
                          error={!!errors.areaId}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: values.areaId ? (
                              <img
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                  borderRadius: '50%',
                                  objectFit: 'cover',
                                  display: 'block',
                                }}
                                src={findArea(values.areaId)?.imageUrl}
                                alt={findArea(values.areaId)?.label}
                              />
                            ) : null,
                          }}
                        />
                      </Grid>
                    );
                  }}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <img
                          style={{
                            width: 50,
                            height: 50,
                            marginRight: 10,
                            borderRadius: '50%',
                            objectFit: 'cover',
                            display: 'block',
                          }}
                          src={option.imageUrl}
                          alt={option.label}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  key={(option) => option.id}
                  onChange={(e, value) => {
                    const findSubarea = subareas.find(
                      (el) => el.areaId === value?.id
                    );
                    setFieldValue('subareaId', null);
                    setFieldValue('areaId', value?.id || null);
                    setTimeout(() => {
                      validateField('areaId');
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Autocomplete
                  fullWidth
                  disableClearable
                  id="combo-box-subareas"
                  size="small"
                  options={subareas
                    .filter((el) => el.areaId === values.areaId)
                    .map((el) => el.id)}
                  getOptionLabel={(option) => findSubarea(option)?.label}
                  renderInput={(params) => {
                    return (
                      <Grid>
                        <TextField
                          {...params}
                          label="Subárea"
                          placeholder="Buscar subárea"
                          onBlur={() => {
                            validateField('subareaId');
                          }}
                          helperText={errors.subareaId || null}
                          error={!!errors.subareaId}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: values.subareaId ? (
                              <img
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                  borderRadius: '50%',
                                  objectFit: 'cover',
                                  display: 'block',
                                }}
                                src={findSubarea(values.subareaId)?.imageUrl}
                                alt={findSubarea(values.subareaId)?.label}
                              />
                            ) : null,
                          }}
                        />
                      </Grid>
                    );
                  }}
                  renderOption={(props, _option, { selected }) => {
                    const option = findSubarea(_option);
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <img
                          style={{
                            width: 50,
                            height: 50,
                            marginRight: 10,
                            borderRadius: '50%',
                            objectFit: 'cover',
                            display: 'block',
                          }}
                          src={option.imageUrl}
                          alt={option.label}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  key={(option) => option.id}
                  onChange={(e, value) => {
                    setFieldValue('subareaId', value || null);
                    setTimeout(() => {
                      validateField('subareaId');
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <LocalizationProvider
                  adapterLocale={'es'}
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    label={'Fecha'}
                    value={values.date || null}
                    openTo="day"
                    size="small"
                    adapterLocale={'ve'}
                    shouldDisableDate={(date) => {
                      const nowTimezone = dayjs().utcOffset(utcOffset).startOf('day').format();
                      const dateTimezone = dayjs(date).utcOffset(utcOffset).set('days', date.get('days')).startOf('day').format();
                      if (new Date(nowTimezone) > new Date(dateTimezone)) {
                        return true;
                      }

                      if (findArea(values.areaId)?.blockReservation === 'day') {
                        if (
                          currentReservations.some((el) =>
                            dayjs(el.entryTime).isSame(date, 'day')
                          )
                        ) {
                          return true;
                        }
                      }
                      return false;
                    }}
                    renderDay={(day, _value, DayComponentProps) => {
                      const isReserved =
                        currentReservations.some((el) =>
                          dayjs(el.entryTime).isSame(day, 'day')
                        ) &&
                        findArea(values.areaId)?.blockReservation === 'day';
                      return (
                        <div style={{ position: 'relative' }}>
                          {isReserved && (
                            <span
                              style={{
                                position: 'absolute',
                                top: '3px',
                                right: '18px',
                                width: '5px',
                                height: '5px',
                                display: 'block',
                                backgroundColor: '#000',
                                borderRadius: '100%',
                                zIndex: '10',
                              }}
                            ></span>
                          )}
                          <PickersDay {...DayComponentProps} />
                        </div>
                      );
                    }}
                    onChange={(date) => {
                      setFieldValue('date', date.toDate());
                      setTimeout(() => {
                        validateField('date');
                      });
                    }}
                    views={['day']}
                    minDate={dayjs().subtract(10, 'day').toDate()}
                    // InputProps={{
                    //   style: {
                    //     height: 56,
                    //   },
                    // }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        required
                        onBlur={() => {
                          validateField('date');
                        }}
                        {...params}
                        helperText={errors.date || null}
                        error={!!errors.date}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <ReservasHours
              area={findArea(values.areaId)}
              subarea={findSubarea(values.subareaId)}
              onChange={(reservations) => {
                setFieldValue('reservations', reservations);
              }}
              onChangeCurrenReservations={(reservations) => {
                setCurrentReservations(reservations);
              }}
            />
            <Grid item md={12} align="center" sx={{ m: 2 }}>
              {!!findSubarea(values.subareaId)?.price && (
                <Box sx={{ marginBottom: 1 }}>
                  Total: $
                  {(values.reservations?.length || 0) *
                    findSubarea(values.subareaId)?.price}
                </Box>
              )}
              <BotonNuevoComponent
                click={handleSubmit}
                disabled={loading}
                text="GUARDAR"
                morado
              />
            </Grid>
            <Modal
              id="modalFormReserva"
              title={'Nueva Usuario'}
              open={modalFormUser}
              maxWidth="sm"
              onClose={() => setModalFormUser(!modalFormUser)}
            >
              <FormUser
                setOpenModalForm={(val) => setModalFormUser(val)}
                onSuccess={(user) => {
                  const userCode = user.userCodes.find((uc) => uc.code.entity.id === entidadesStore.activo.id);
                  const userCodeData = {
                    id: userCode.id,
                    name: `${user.profile.firstName} ${user.profile.lastName}`,
                    avatar: userCode.avatar || user.profile.avatar || null,
                    email: user.email,
                    documentId: `${user.profile.typeDocument}-${user.profile.identificationNumber}`,
                    label: `(${userCode.code.code}) - ${user.profile.firstName} ${user.profile.lastName}`,
                  };
                  setUserCodes([...userCodes, userCodeData]);
                  console.log(userCode, user)
                  setFieldValue('userCodeId', userCode.id);
                  setUserCodeValue(userCodeData);
                  setTimeout(() => {
                    validateField('userCodeId');
                  });
                  setModalFormUser(false);
                  addNotification('Usuario asignado correctamente', { success: true });
                }}
                onClose={() => setModalFormUser(false)}
              />
            </Modal>
          </Grid>
        )}
      </Formik>
    </Box>
  );
};

export default FormReserva;
