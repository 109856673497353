import { Grid } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../components';
import { SU } from '../../constants/ConfigConst';
import { getAllAreasAction } from '../areas/AreasAction';
import BotonNuevoComponent from '../entidades/components/BotonNuevo';
import {
  checkModuleAccess,
  isManagerUserCode,
} from '../modulos/modules.helpers';
import { saveReservasEspecialesAction } from './ReservasEspecialesAction';
import FormReservasEspeciales from './components/FormReservasEspeciales';
import ListadoReservasEspeciales from './components/ListadoReservasEspeciales';

const ReservasEspecialesModule = () => {
  const dispatch = Redux.useDispatch();

  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const areasStore = Redux.useSelector((state) => state.areas);
  const reservasEspecialesStore = Redux.useSelector(
    (state) => state.reservasEspeciales
  );

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);
  const accessName = 'reservation';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  const [isMobile, setIsMobile] = React.useState(false);
  const [openModalForm, setOpenModalForm] = React.useState(false);

  React.useEffect(async () => {
    if (areasStore.all && areasStore.all.length === 0) {
      await getAllAreasAction(dispatch, {
        entityId: entidadesStore.activo.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        {!isManager && (
          <BotonNuevoComponent
            click={async () => {
              await saveReservasEspecialesAction(dispatch, null);
              setOpenModalForm(true);
            }}
            azul
            mr={2}
          />
        )}
      </Grid>
      <Grid item md={12}>
        <ListadoReservasEspeciales />
      </Grid>
      {!isManager && (
        <Modal
          id="modalFormReservaEspeciales"
          title={`${
            reservasEspecialesStore.activo ? 'Editar' : 'Nuevo'
          } bloqueo de reserva`}
          open={openModalForm}
          maxWidth="sm"
          onClose={() => setOpenModalForm(!openModalForm)}
        >
          <FormReservasEspeciales setIsMobile={() => setIsMobile(!isMobile)} />
        </Modal>
      )}
    </Grid>
  );
};

export default ReservasEspecialesModule;
