import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import * as CONST from '../../../constants/FieldsConst';
import { dateformat, getDateFormated, mesesArray } from '../../../helpers';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { useNotification } from '../../../helpers/notification';
import {
  GET_DATA_SEARCH_AREAS_RESERVAS_ESPECIALES,
  GET_DATE_SEARCH_RESERVAS_ESPECIALES,
} from '../../../store/Types';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { reservations } from '../../reservas/ReservasService';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import {
  deleteReservasEspecialesAction,
  getAllReservasEspecialesAction,
  setActivoReservasEspecialesAction,
} from '../ReservasEspecialesAction';

const ListadoReservasEspeciales = () => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const areasStore = Redux.useSelector((state) => state.areas);
  const reservasEspecialesStore = Redux.useSelector(
    (state) => state.reservasEspeciales
  );

  const [area, setArea] = React.useState('');
  const [areaload, setAreaLoad] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isParentBlock, setIsParentBlock] = React.useState(false);

  const timezone = entidadesStore.activo.country.timezone;

  React.useEffect(() => {
    setArea(reservasEspecialesStore.dataSearchAreaId ?? '');

    setTimeout(() => {
      setAreaLoad(true);
    }, 500);

    if (
      reservasEspecialesStore.dateSearch &&
      reservasEspecialesStore.dataSearchAreaId
    ) {
      getAllReservasEspecialesAction(dispatch, entidadesStore.activo.id, {
        areaId: reservasEspecialesStore.dataSearchAreaId,
        date: dayjs(reservasEspecialesStore.dateSearch)
          .startOf('month')
          .toDate(),
        dateTo: dayjs(reservasEspecialesStore.dateSearch)
          .endOf('month')
          .toDate(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reservasEspecialesStore.dateSearch,
    reservasEspecialesStore.dataSearchAreaId,
  ]);

  React.useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: GET_DATE_SEARCH_RESERVAS_ESPECIALES,
        payload: dateformat(null, {}),
      });
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const rows = reservasEspecialesStore.all
    .reduce((acc, reservation, i) => {
      const index = acc.findIndex((item) => item.parentBlockId === reservation.parentBlockId);
      if (index === -1) {
        reservation.totalBlock = 1;
        acc.push({
          ...reservation,
          dateFrom: getDateFormated(reservation.entryTime, 'DD-MM-YYYY', timezone),
          entryTime: getDateFormated(reservation.entryTime, 'HH:mm a', timezone),
          departureTime: getDateFormated(reservation.departureTime, 'HH:mm a', timezone),
        });
      } else if (reservasEspecialesStore.all[i + 1]?.parentBlockId !== reservation.parentBlockId) {
        acc[index].departureTime = getDateFormated(reservation.departureTime, 'HH:mm a', timezone);
        acc[index].totalBlock++;
      } else {
        acc[index].totalBlock++;
      }

      return acc;
    }, [])
    setRows(rows);
  }, [reservasEspecialesStore.all]);

  const _handleDeleteClick = async () => {
    setLoadingDelete(true);
    await deleteReservasEspecialesAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      reservasEspecialesStore.activo.id,
      { parent: isParentBlock },
      {
        areaId: reservasEspecialesStore.dataSearchAreaId,
        date: dayjs(reservasEspecialesStore.dateSearch)
          .startOf('month')
          .toDate(),
        dateTo: dayjs(reservasEspecialesStore.dateSearch)
          .endOf('month')
          .toDate(),
      }
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'parentBlockId',
      options: {
        display: 'excluded',
      },
    },
    {
      name: CONST.DESCRIPTION,
      label: 'Motivo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <NameComponent>{value}</NameComponent>;
        },
      },
    },
    {
      name: 'subarea',
      label: 'Subárea',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <NameComponent>{value?.name}</NameComponent>;
        },
      },
    },
    {
      name: CONST.DATE_FROM,
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.ENTRY_TIME,
      label: 'Hora de entrada',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: CONST.DEPARTURE_TIME,
      label: 'Hora de salida',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box align="left" sx={{ display: 'flex', flexDirection: 'row' }}>
                <ActionsList
                  id={tableMeta.rowData[0]}
                  onDelete={async () => {
                    const data = reservasEspecialesStore.all.find(
                      (el) => el.id === tableMeta.rowData[0]
                    );
                    setIsParentBlock(true);
                    await setActivoReservasEspecialesAction(dispatch, data);
                    setIsDelete(true);
                  }}
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onDownload: () => {
      const url = `${reservations}/export`;
      const name = 'reservaciones';
      const params = {
        entityId: entidadesStore.activo.id,
        areaId: reservasEspecialesStore.dataSearchAreaId,
        date: dayjs(reservasEspecialesStore.dateSearch)
          .startOf('month')
          .toDate(),
        dateTo: dayjs(reservasEspecialesStore.dateSearch)
          .endOf('month')
          .toDate(),
        type: 'block',
        groupByBlockId: false,
      };
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      const [, parentBlockId] = rowData;
      const reservationGroup = reservasEspecialesStore.all.filter( el => el.parentBlockId === parentBlockId);
      const data = reservationGroup.reduce((acc, reservation, i) => {
        const index = acc.findIndex((item) => item.blockId === reservation.blockId);
        if (index === -1) {
          reservation.totalBlock = 1;
          acc.push({
            ...reservation,
            dateFrom: getDateFormated(reservation.entryTime, 'DD-MM-YYYY', timezone),
            entryTime: getDateFormated(reservation.entryTime, 'HH:mm a', timezone),
            departureTime: getDateFormated(reservation.departureTime, 'HH:mm a', timezone)
          });
        } else if (reservationGroup[i + 1]?.blockId !== reservation.blockId) {
          acc[index].departureTime = getDateFormated(reservation.departureTime, 'HH:mm a', timezone);
          acc[index].totalBlock++;
        } else {
          acc[index].totalBlock++;
        }
        return acc;
      }, [])
      return (
        
      //   <TableRow>
      //   <TableCell colSpan={colSpan}>Custom expandable row option. Data: {JSON.stringify(rowData)}</TableCell>
      // </TableRow>
      <TableRow>
        <TableCell colSpan={6} style={{ paddingLeft: '50px' }}>
          <MUIDataTable
            data={data}
            columns={[
              {
                name: CONST.ID,
                options: {
                  display: 'excluded',
                },
              },
              {
                name: 'parentBlockId',
                options: {
                  display: 'excluded',
                },
              },
              {
                name: CONST.DATE_FROM,
                label: 'Fecha',
                options: {
                  filter: true,
                  sort: true,
                },
              },
              {
                name: CONST.ENTRY_TIME,
                label: 'Hora de entrada',
                options: {
                  filter: true,
                  sort: true,
                },
              },
              {
                name: CONST.DEPARTURE_TIME,
                label: 'Hora de salida',
                options: {
                  filter: true,
                  sort: true,
                },
              },
          
              {
                name: CONST.ACTIONS,
                label: 'Acción',
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <>
                        <Box align="left" sx={{ display: 'flex', flexDirection: 'row' }}>
                          <ActionsList
                            id={tableMeta.rowData[0]}
                            onDelete={async () => {
                              const data = reservasEspecialesStore.all.find(
                                (el) => el.id === tableMeta.rowData[0]
                              );
                              setIsParentBlock(false);
                              await setActivoReservasEspecialesAction(dispatch, data);
                              setIsDelete(true);
                            }}
                          />
                        </Box>
                      </>
                    );
                  },
                },
              },
            ]}
            options={{
              ...options,
              filter: false,
              print: false,
              download: false,
              selectableRows: false,
              search: false,
              viewColumns: false,
              hideFilterIcons: true,
              expandableRows: false,
              pagination: false,
            }}
          />
        </TableCell>
      </TableRow>
    );
    },
    selectableRowsHideCheckboxes: true,
  };

  const selectAreas = areaload && (
    <FormControl size="small" sx={{ width: '100%' }}>
      <InputLabel id="selectAreas">Áreas</InputLabel>
      <Select
        value={area}
        labelId="selectAreas"
        label="Areas"
        size="small"
        onChange={(e) => {
          dispatch({
            type: GET_DATA_SEARCH_AREAS_RESERVAS_ESPECIALES,
            payload: e.target.value,
          });
        }}
        id={CONST.AREA_ID}
      >
        {areasStore.all.map((el) => {
          return (
            <MenuItem key={`areas-${el.id}`} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const selectMes = (
    <FormControl size="small" sx={{ width: '100%' }}>
      <InputLabel id="selectMeses">Mes</InputLabel>
      <Select
        labelId="selectMeses"
        size="small"
        value={dateformat(reservasEspecialesStore.dateSearchOrigin, {
          format: 'MM',
        })}
        label="Mes"
        onChange={(e) => {
          const data = `${dateformat(null, { format: 'YYYY' })}-${
            e.target.value
          }-05`;
          dispatch({
            type: GET_DATE_SEARCH_RESERVAS_ESPECIALES,
            payload: dateformat(data, {}),
          });
        }}
        id={CONST.DATE_TO}
      >
        {mesesArray.map((mes, index) => (
          <MenuItem key={index + 1} value={mes.valor}>
            {mes.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper elevation={4} sx={{ p: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              {selectMes}
            </Grid>
            <Grid item xs={12} md={3}>
              {selectAreas}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={12}>
        <LoadingComponent
          isLoading={reservasEspecialesStore.loadingGetAll || loadingDelete}
          text="Cargando reservas..."
        />
        <MUIDataTable
          title={`Listado de bloqueos`}
          data={rows}
          columns={columns}
          options={options}
        />
      </Grid>
      {reservasEspecialesStore.activo && (
        <Modal
          id="modalDeleteReserva"
          title={`Eliminar bloque de reserva`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este bloque de reserva?
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '15px',
                }}
              >
                <div>
                  Motivo:&nbsp;&nbsp;&nbsp;
                  <strong>{reservasEspecialesStore.activo.description}</strong>
                </div>
                <div>
                  Entrada:&nbsp;&nbsp;&nbsp;
                  <strong>
                    {dateformat(reservasEspecialesStore.activo.entryTime, {
                      format: 'YYYY-MM-DD HH:mm',
                    })}
                  </strong>
                </div>
                <div>
                  Salida:&nbsp;&nbsp;&nbsp;
                  <strong>
                    {dateformat(reservasEspecialesStore.activo.departureTime, {
                      format: 'YYYY-MM-DD HH:mm',
                    })}
                  </strong>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={_handleDeleteClick}
            >
              ELIMINAR
            </Button>
            <Button onClick={() => setIsDelete(false)}>CANCELAR</Button>
          </DialogActions>
        </Modal>
      )}
    </Grid>
  );
};

export default ListadoReservasEspeciales;
