import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid, Paper, Tab, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../components';
import * as ROUTES from '../../../router/Routes';
import FormClases from '../clases/components/FormClases';
import FormClasesProfesor from '../clases/components/FormClasesProfesor';
import InfoClasesComponent from './InfoClases';
// import { getOneClasesAction } from '../clases/ClasesAction';
import { checkModuleAccess } from '../../modulos/modules.helpers';
import { getOneClasesAction } from '../clases/ClasesAction';
import {
  getAllClasesDocentesAction,
  setActivoClasesDocentesAction,
} from '../clases_docentes/ClasesDocentesAction';
import CuposClasesView from '../cupos/CuposClasesView';
import ReservacionesClasesView from '../reservaciones/ReservacionesClasesView';
import ListadoClasesProfesores from '../reservaciones/components/ListadoClasesProfesores';

const PanelClasesView = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const clasesStore = Redux.useSelector((state) => state.clases);
  const clasesDocentesStore = Redux.useSelector(
    (state) => state.clasesDocentes
  );
  const loginStore = Redux.useSelector((state) => state.login);

  const accessName = 'classes';
  if (!checkModuleAccess(accessName, loginStore)) {
    window.location.href = '/dashboard';
  }

  React.useEffect(() => {
    if (clasesStore.activo === null || clasesStore.activo === undefined) {
      history.push(ROUTES.panelClases.to);
      return;
    }
  }, [clasesStore.all]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!clasesStore.activo) {
    window.location.href = '/clases';
  }

  React.useEffect(() => {
    if (clasesStore.activo) {
      try {
        getOneClasesAction(dispatch, clasesStore.activo.id).then((res) => {
          getAllClasesDocentesAction(dispatch, {
            classId: clasesStore.activo.id,
            entityId: entidadesStore.activo.id,
          });
        });
      } catch (error) {
        window.location.href = '/clases';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openModalForm, setOpenModalForm] = React.useState(false);
  const [openModalFormTeacher, setOpenModalFormTeacher] = React.useState(false);

  const [tab, setTab] = React.useState(1);

  const handleSelectTeacher = async (val) => {
    if (typeof val === 'boolean') {
      if (val && clasesDocentesStore.activo) {
        await setActivoClasesDocentesAction(dispatch, null);
      }
    } else if (val === null) {
      await setActivoClasesDocentesAction(dispatch, null);
    } else if (val) {
      const item = clasesDocentesStore.all.find((j) => j.id === val);
      await setActivoClasesDocentesAction(dispatch, item);
    }
  }

  const handleEditTeacher = async (val) => {
    handleSelectTeacher(val);
    if (typeof val === 'boolean') {
      setOpenModalFormTeacher(val);
    } else if (val === null) {
      setOpenModalFormTeacher(false);
    } else if (val) {
      setOpenModalFormTeacher(true);
    }
  }

  const tabPanel = (
    <Paper elevation={2}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                classes={{
                  indicator: {
                    backgroundColor: 'red',
                  },
                }}
                onChange={(e, nv) => setTab(nv)}
                aria-label="tabs-users"
              >
                <Tab label="Profesores" value={1} />
                <Tab label="Cupos" value={2} />
                <Tab label="Reservaciones" value={3} />
              </TabList>
            </Box>
            <TabPanel value={1} sx={{ p: 0 }}>
              <ListadoClasesProfesores handleSelectTeacher={handleSelectTeacher} handleEditTeacher={handleEditTeacher} />
            </TabPanel>
            <TabPanel value={2} sx={{ p: 0 }}>
              <CuposClasesView />
            </TabPanel>
            <TabPanel value={3} sx={{ p: 0 }}>
              <ReservacionesClasesView />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
      <Grid item md={4}>
        <InfoClasesComponent
          setOpenModalForm={(val) => setOpenModalForm(val)}
          setOpenModalFormTeacher={handleEditTeacher}
        />
      </Grid>
      <Grid item md={8}>
        {tabPanel}
      </Grid>
      <Modal
        id="modalFormClases"
        title={`${clasesStore.activo ? 'Editar' : 'Nueva'} área`}
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
        maxWidth="sm"
      >
        {clasesStore.activo && (
          <Typography variant="h6">
            {clasesStore.activo.nombres} {clasesStore.activo.apellidos}
          </Typography>
        )}
        <FormClases
          activo={clasesStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
      <Modal
        id="modalFormProfesorClases"
        title={`${clasesDocentesStore?.activo ? 'Editar' : 'Nueva'} área`}
        open={openModalFormTeacher}
        onClose={() => setOpenModalFormTeacher(!openModalFormTeacher)}
        maxWidth="sm"
      >
        <FormClasesProfesor
          activo={clasesStore.activo}
          setOpenModalForm={(val) => setOpenModalFormTeacher(val)}
        />
      </Modal>
    </Grid>
  );
};

export default PanelClasesView;
