import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import * as React from 'react';
import * as Redux from 'react-redux';
import { ButtonFile } from '../../../components';
import { acceptOnlyImages } from '../../../components/ButtonFile';
import LoadingComponent from '../../../components/Loading';
import { SU } from '../../../constants/ConfigConst';
import * as CONST from '../../../constants/FieldsConst';
import { HOUR_BLOCK } from '../../../constants/enumsConst';
import { dateformat } from '../../../helpers';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../helpers/notification';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import {
  checkModuleActionAccess,
  isManagerUserCode,
} from '../../modulos/modules.helpers';
import { saveSubAreasAction } from '../SubAreasAction';

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `subarea-tab-${index}`,
    'aria-controls': `subarea-tabpanel-${index}`,
  };
}

const FormSubAreas = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const areasStore = Redux.useSelector((state) => state.areas);
  const subareasStore = Redux.useSelector((state) => state.subareas);
  const loginStore = Redux.useSelector((state) => state.login);
  const entidadesStore = Redux.useSelector((state) => state.entidades);

  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);

  const [fileImage, setFileImage] = React.useState();
  const isHourBlock = areasStore.activo.blockReservation === HOUR_BLOCK;
  const interval = isHourBlock ? 30 : 1440;
  const minutesUse = isHourBlock ? 30 : 1440;

  // const defaultDates = [
  //   {
  //     day: 0,
  //     startTime: areasStore.activo.openingTime || '00:00:00',
  //     endTime: areasStore.activo.closingTime || '23:59:59',
  //   },
  // ]

  const defaultDates = Array.from({ length: 7 }, (_, i) => {
    return {
      day: i,
      startTime: areasStore.activo.openingTime || '00:00:00',
      endTime: areasStore.activo.closingTime || '23:59:59',
    };
  });

  const action = {
    read: checkModuleActionAccess('subareas', 'read', loginStore),
    create: checkModuleActionAccess('subareas', 'create', loginStore),
    delete: checkModuleActionAccess('subareas', 'delete', loginStore),
    update: checkModuleActionAccess('subareas', 'update', loginStore),
  };

  const [subarea, setSubArea] = React.useState({
    [CONST.NAME]: '',
    [CONST.OPENING_TIME]: areasStore.activo.openingTime,
    [CONST.CLOSING_TIME]: areasStore.activo.closingTime,
    [CONST.MAX_PERSONS]: 1,
    [CONST.MIN_PERSONS]: 1,
    [CONST.INTERVAL]: interval,
    [CONST.MINUTES_USE]: minutesUse,
    [CONST.MAX_PERSONS_SHARED]: 1,
    [CONST.PRICE]: 0,
    [CONST.ACTIVE]: true,
    [CONST.DAYS_FOR_RESERVE]: 7,
    [CONST.HOURS_FOR_RESERVE]: '00:00',
    [CONST.MIN_PREV_FOR_RESERVE]: 60,
    [CONST.ACTIVE]: true,
    notifyNotAttendees: false,
    areAllTimesEqual: true,
    dates: defaultDates,
    participantType: 'total_only',
  });
  const [dataError, setDataRequired] = React.useState({
    [CONST.NAME]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [tabs, setTabs] = React.useState(0);

  const onTabs = (event, newValue) => {
    setTabs(newValue);
  };

  React.useEffect(() => {
    if (subareasStore.activo === null) return;
    let data = {};
    Object.keys(subarea).map((key) => (data[key] = subareasStore.activo[key]));
    data = {
      ...data,
      [CONST.OPENING_TIME]:
        subareasStore?.activo.openingTime || areasStore.activo.openingTime,
      [CONST.CLOSING_TIME]:
        subareasStore?.activo.closingTime || areasStore.activo.closingTime,
      interval: isHourBlock ? subareasStore.activo.interval : 1440,
      minutesUse: isHourBlock ? subareasStore.activo.minutesUse : 1440,
      areAllTimesEqual: subareasStore.activo.areAllTimesEqual,
      dates: subareasStore.activo.dates,
    };
    setSubArea(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subareasStore.activo]);

  const _handleSetDataFieldArea = (key, value) =>
    setSubArea({ ...subarea, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = subarea[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const handleSubmit = async () => {
    const valid = _validDataForm();
    if (areasStore.activo === null) return alert('Falta elegir un área');
    if (valid) {
      const data = {
        ...subarea,
        [CONST.AREA_ID]: areasStore.activo.id,
        [CONST.OPENING_TIME]: subarea.openingTime,
        [CONST.CLOSING_TIME]: dateformat(subarea.closingTime, {
          complete: true,
          isHHmmss: true,
        }),
        [CONST.HOURS_FOR_RESERVE]: dateformat(subarea.hoursForReserve, {
          complete: true,
          isHHmmss: true,
        }),
      };
      if (fileImage) data[CONST.IMAGE] = fileImage;
      setLoadingSave(true);
      let res = await saveSubAreasAction(
        { dispatch, addNotification },
        subareasStore.activo ? subareasStore.activo.id : null,
        data,
        subareasStore.params
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  const infoForm = (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <ButtonFile
              id={CONST.IMG_FOTO}
              onFile={(file) => setFileImage(file)}
              file={fileImage}
              text="Subir imagén del sub área"
              readOnly={isManager}
              sxc={{ marginTop: '5px' }}
              size="40%"
              name={subareasStore.activo ? subareasStore.activo.name : null}
              onDelete={() => setFileImage(null)}
              accept={acceptOnlyImages}
              src={
                fileImage
                  ? null
                  : subareasStore.activo
                  ? subareasStore.activo.imageUrl
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  size="small"
                  id={CONST.NAME}
                  required
                  label="Nombre"
                  inputProps={{ readOnly: isManager }}
                  value={subarea.name}
                  onChange={(e) =>
                    _handleSetDataFieldArea(CONST.NAME, e.target.value)
                  }
                  helperText={dataError.name ? validFielddHelper(1) : null}
                  error={dataError.name}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-estatus-label">Estatus</InputLabel>
                  <Select
                    labelId="select-estatus-label"
                    size="small"
                    readOnly={isManager}
                    value={subarea.active}
                    label="Estatus"
                    onChange={(e) =>
                      _handleSetDataFieldArea(CONST.ACTIVE, e.target.value)
                    }
                    id={CONST.ACTIVE}
                  >
                    <MenuItem value={true}>Activo</MenuItem>
                    <MenuItem value={false}>Bloqueado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const tablaDias = (
    <TableContainer sx={{ width: '100%' }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ width: '40%' }}>
              Día
            </TableCell>
            <TableCell align="center">Hora de apertura</TableCell>
            <TableCell align="center">Hora de cierre</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subarea.dates.map((item, index) => {
            return (
              <TableRow
                key={`row_${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">
                  {dayjs().day(item.day).format('dddd').toLocaleUpperCase()}
                </TableCell>
                <TableCell align="left">
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      size="small"
                      type="time"
                      value={item.startTime}
                      onChange={(e) => {
                        const dates = [...subarea.dates];
                        dates[index].startTime = `${e.target.value}:00`;
                        setSubArea({ ...subarea, dates });
                      }}
                      id={CONST.START_TIME}
                    />
                  </FormControl>
                </TableCell>
                <TableCell align="left">
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      size="small"
                      type="time"
                      value={item.endTime}
                      onChange={(e) => {
                        const dates = [...subarea.dates];
                        dates[index].endTime = `${e.target.value}:00`;
                        setSubArea({ ...subarea, dates });
                      }}
                      id={CONST.CLOSING_TIME}
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const horariosForm = (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={subarea.areAllTimesEqual}
                onChange={(e) => {
                  let dates = [...subarea.dates];
                  if (e.target.checked) {
                    dates = dates.map((d) => {
                      return {
                        ...d,
                        startTime: subarea.openingTime,
                        endTime: subarea.closingTime,
                      };
                    });
                  }
                  setSubArea({
                    ...subarea,
                    areAllTimesEqual: e.target.checked,
                    dates,
                  });
                }}
              />
            }
            label="Aplicar las mismas horas horas a todos los días de la semana"
          />
        </FormControl>
      </Grid>
      {isHourBlock && subarea.areAllTimesEqual && (
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <Typography variant="body">Hora de apertura</Typography>
            <TextField
              size="small"
              type="time"
              inputProps={{ readOnly: isManager, min: '12:00', max: '20:00' }}
              value={subarea.openingTime}
              onChange={(e) => {
                const dates = [...subarea.dates];
                const value = `${e.target.value}:00`;
                setSubArea({
                  ...subarea,
                  openingTime: value,
                  dates: dates.map((d) => ({ ...d, startTime: value })),
                });
              }}
              id={CONST.OPENING_TIME}
            />
          </FormControl>
        </Grid>
      )}
      {isHourBlock && subarea.areAllTimesEqual && (
        <Grid item xs={12} md={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <Typography variant="body">Hora de cierre</Typography>
            <TextField
              size="small"
              type="time"
              inputProps={{ readOnly: isManager }}
              value={subarea.closingTime}
              onChange={(e) => {
                const dates = [...subarea.dates];
                const value = `${e.target.value}:00`;
                setSubArea({
                  ...subarea,
                  closingTime: value,
                  dates: dates.map((d) => ({ ...d, endTime: value })),
                });
              }}
              id={CONST.CLOSING_TIME}
            />
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} md={12}>
        {!subarea.areAllTimesEqual && tablaDias}
      </Grid>

      {/* <pre style={{ position: 'fixed', top: '0', left: '0', zIndex: '1000', background: 'white' }}>
        {JSON.stringify( subarea, null, 2)}
      </pre> */}
    </Grid>
  );

  const validacionesForm = (
    <Grid container spacing={1}>
      {isHourBlock && (
        <Grid item xs={12} md={8}>
          <Typography variant="body">
            Intervalo de bloques <br />
            <br />
          </Typography>
        </Grid>
      )}
      {isHourBlock && (
        <Grid item xs={12} md={4}>
          <TextField
            size="small"
            type="number"
            value={subarea.interval}
            onChange={(e) =>
              _handleSetDataFieldArea(CONST.INTERVAL, e.target.value)
            }
            id={CONST.INTERVAL}
            InputProps={{
              inputProps: { min: 1, readOnly: isManager },
            }}
          />
        </Grid>
      )}
      {isHourBlock && (
        <Grid item xs={12} md={8}>
          <Typography variant="body">Minutos de uso</Typography>
        </Grid>
      )}
      {isHourBlock && (
        <Grid item xs={12} md={4}>
          <TextField
            size="small"
            type="number"
            value={subarea.minutesUse}
            onChange={(e) =>
              _handleSetDataFieldArea(CONST.MINUTES_USE, e.target.value)
            }
            id={CONST.MINUTES_USE}
            InputProps={{
              inputProps: { min: 0, readOnly: isManager },
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <Typography variant="body">Cantidad de personas compartida</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          type="number"
          value={subarea.maxPersonsShared}
          onChange={(e) =>
            _handleSetDataFieldArea(CONST.MAX_PERSONS_SHARED, e.target.value)
          }
          id={CONST.MAX_PERSONS_SHARED}
          InputProps={{
            inputProps: { min: 0, readOnly: isManager },
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body">Precio</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          type="number"
          value={subarea.price}
          onChange={(e) => _handleSetDataFieldArea(CONST.PRICE, e.target.value)}
          id={CONST.PRICE}
          InputProps={{
            inputProps: { min: 0, readOnly: isManager },
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body">
          A partir de cuantos días previos se puede reservar
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          type="number"
          value={subarea.daysForReserve}
          onChange={(e) =>
            _handleSetDataFieldArea(CONST.DAYS_FOR_RESERVE, e.target.value)
          }
          id={CONST.DAYS_FOR_RESERVE}
          InputProps={{
            inputProps: { min: 0, readOnly: isManager },
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body">
          A partir de que hora se puede reservar
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="standard" sx={{ width: '73%' }}>
          <TextField
            size="small"
            type="time"
            value={subarea.hoursForReserve}
            inputProps={{ readOnly: isManager }}
            onChange={(e) =>
              _handleSetDataFieldArea(CONST.HOURS_FOR_RESERVE, e.target.value)
            }
            id={CONST.HOURS_FOR_RESERVE}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body">
          Minutos cercanos a una reserva para que cualquier usuario la pueda
          reservar, sin importar las validaciones
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          type="number"
          value={subarea.minPrevForReserve}
          onChange={(e) =>
            _handleSetDataFieldArea(CONST.MIN_PREV_FOR_RESERVE, e.target.value)
          }
          id={CONST.MIN_PREV_FOR_RESERVE}
          InputProps={{
            inputProps: { min: 0, readOnly: isManager },
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body">Mínimo de personas</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          type="number"
          value={subarea.minPersons}
          onChange={(e) => {
            setSubArea({ ...subarea, minPersons: e.target.value });
          }}
          id={CONST.MIN_PERSONS}
          InputProps={{
            inputProps: { min: 1 },
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="body">Máximo de personas</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          type="number"
          value={subarea.maxPersons}
          onChange={(e) => {
            setSubArea({ ...subarea, maxPersons: e.target.value });
          }}
          id={CONST.MAX_PERSONS}
          InputProps={{
            inputProps: { min: 1 },
          }}
        />
      </Grid>

      <Grid item xs={12} md={8}>
        <Typography variant="body">Tipo de ingreso de personas</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          {/* <InputLabel id="select-estatus-label">Tipo de ingreso de personas</InputLabel> */}
          <Select
            labelId="select-estatus-label"
            size="small"
            value={subarea.participantType}
            // label="Tipo de ingreso de personas"
            onChange={(e) => {
              setSubArea({ ...subarea, participantType: e.target.value });
            }}
            id="participantType"
          >
            <MenuItem value="total_only">Solo total</MenuItem>
            <MenuItem value="detailed">Detallado</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={subarea.notifyNotAttendees}
                onChange={(e) => {
                  setSubArea({ ...subarea, notifyNotAttendees: e.target.checked });
                }}
              />
            }
            label="Notificar a los usuarios que no asistieron"
          />
        </FormControl>
      </Grid>
    </Grid>
  );

  return (
    <Box component="form" sx={{}}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={subareasStore.activo ? 'Actualizando...' : 'Guardando...'}
          />

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabs}
              onChange={onTabs}
              aria-label="basic tabs example"
            >
              <Tab label="Información" {...a11yProps(0)} />
              <Tab label="Validaciones" {...a11yProps(1)} />
              <Tab label="Horarios" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabs} index={0}>
            {infoForm}
          </CustomTabPanel>
          <CustomTabPanel value={tabs} index={1}>
            {validacionesForm}
          </CustomTabPanel>
          <CustomTabPanel value={tabs} index={2}>
            {horariosForm}
          </CustomTabPanel>

          {/* <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography variant="body" sx={{ fontWeight: 'bold' }}>
                Información
              </Typography>
              <Divider sx={{ mb: 1 }}></Divider>
              {tabPanel1}
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography variant="body" sx={{ fontWeight: 'bold' }}>
                Validaciones
              </Typography>
              <Divider sx={{ mb: 1 }}></Divider>
              {tabPanel2}
            </Grid>
          </Grid> */}
        </Grid>
        {((subareasStore.activo && action.update) ||
          (!subareasStore.activo && action.create)) && (
          <Grid item md={12} align="center" sx={{ m: 2 }}>
            <BotonNuevoComponent
              click={handleSubmit}
              text="GUARDAR"
              disabled={loadingSave}
              morado
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

FormSubAreas.propTypes = {
  getAll: PropTypes.func,
  setOpenModalForm: PropTypes.func,
};

export default FormSubAreas;
