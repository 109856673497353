import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Router from './router/Router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store/Store';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { createTheme } from '@mui/material';
import { purple } from '@mui/material/colors';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { TOKEN } from './constants/ConfigConst';
import { SET_LOGOUT } from './store/Types';
import { useNotification } from './helpers/notification';
import { getSettingsAction } from './modules/settings/SettingsAction';
import { isToken } from './services/Apis';
import '@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css';
import './assets/style.css';
import { PERSIST } from 'redux-persist';
import axios from 'axios';

// Configuracion la hora de Venezuela
// export const TIMEZONE = 'America/Caracas';
// var timezone = require('dayjs/plugin/timezone');
// dayjs.extend(timezone);
// dayjs.tz.setDefault(TIMEZONE);

const theme = createTheme({
  palette: {
    primary: {
      main: purple[700],
    },
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(PERSIST);
      store.dispatch({
        type: SET_LOGOUT,
      });
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

const App = () => {
  const dispatch = store.dispatch;
  const { addNotification } = useNotification();
  if (isToken()) getSettingsAction({ dispatch, addNotification });

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<div>Loading</div>}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={4000}
              onClose={() => {}}
            >
              <Router />
            </SnackbarProvider>
          </Suspense>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};
ReactDOM.render(<App />, document.getElementById('root'));

// https://github.com/azizmashkour/react-i18n/blob/master/src/App.js
