import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid, Paper, Tab, Typography } from '@mui/material';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../../components';
import * as ROUTES from '../../../router/Routes';
import { getOneRestaurantesAction } from './RestaurantesAction';
import FormRestaurantes from './components/FormRestaurantes';
import InfoRestaurantesComponent from './components/InfoRestaurantes';

const PanelRestaurantesView = () => {
  const dispatch = Redux.useDispatch();
  let history = useHistory();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const restaurantesStore = Redux.useSelector((state) => state.restaurantes);

  React.useEffect(() => {
    if (
      restaurantesStore.activo === null ||
      restaurantesStore.activo === undefined
    ) {
      history.push(ROUTES.panelRestaurante.to);
      return;
    }
    if (!restaurantesStore.loadingGetAll) {
      getOneRestaurantesAction(
        dispatch,
        restaurantesStore.activo.id,
        entidadesStore.activo.id
      );
    }
  }, [restaurantesStore.all]); // eslint-disable-line react-hooks/exhaustive-deps

  const [openModalForm, setOpenModalForm] = React.useState(false);

  const [tab, setTab] = React.useState(1);

  const tab1 = (
    <TabPanel value={1} sx={{ p: 0 }}>
      {/* <SubRestaurantesView isBlock={true} /> */}
    </TabPanel>
  );

  const tabPanel = (
    <Paper elevation={2}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                classes={{
                  indicator: {
                    backgroundColor: 'red',
                  },
                }}
                onChange={(e, nv) => setTab(nv)}
                aria-label="tabs-users"
              >
                <Tab label="Sub áreas" value={1} />
              </TabList>
            </Box>
            {tab1}
          </TabContext>
        </Grid>
      </Grid>
    </Paper>
  );

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
      <Grid item md={4}>
        <InfoRestaurantesComponent
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Grid>
      {/* <Grid item md={8}>
        {tabPanel}
      </Grid> */}
      <Modal
        id="modalFormRestaurantes"
        title={`${restaurantesStore.activo ? 'Editar' : 'Nueva'} área`}
        open={openModalForm}
        onClose={() => setOpenModalForm(!openModalForm)}
      >
        {restaurantesStore.activo && (
          <Typography variant="h6">
            {restaurantesStore.activo.nombres}{' '}
            {restaurantesStore.activo.apellidos}
          </Typography>
        )}
        <FormRestaurantes
          activo={restaurantesStore.activo}
          setOpenModalForm={(val) => setOpenModalForm(val)}
        />
      </Modal>
    </Grid>
  );
};

export default PanelRestaurantesView;
