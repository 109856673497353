import { FormControlLabel, Switch, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import * as CONST from '../../../constants/FieldsConst';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';

import { dateformat } from '../../../helpers';
export const DniciField = ({ v, on, ht, e }) => (
  <TextField
    fullWidth
    size="small"
    id={CONST.DNI}
    required
    label="Dni/Ci"
    value={v}
    onChange={on}
    helperText={ht ?? e ? validFielddHelper(1) : null}
    error={e}
  />
);

export const NombresField = ({ v, on, ht, e }) => (
  <TextField
    fullWidth
    size="small"
    id={CONST.NOMBRES}
    required
    label="Nombres"
    value={v}
    onChange={on}
    helperText={ht ?? e ? validFielddHelper(1) : null}
    error={e}
  />
);

export const ApellidosField = ({ v, on, ht, e }) => (
  <TextField
    fullWidth
    size="small"
    id={CONST.APELLIDOS}
    label="Apellidos"
    required
    value={v}
    onChange={on}
    helperText={ht ?? e ? validFielddHelper(1) : null}
    error={e}
  />
);

export const FechaNacimientoField = ({ l, v, on, ht, e, dis }) => (
  <LocalizationProvider adapterLocale={'es'} dateAdapter={AdapterDayjs}>
    <DatePicker
      label={l ?? 'Fecha de nacimiento'}
      value={v || null}
      openTo="year"
      onChange={on}
      views={['year', 'month', 'day']}
      disabled={dis}
      disableFuture={true}
      renderInput={(params) => (
        <TextField
          fullWidth
          size="small"
          error={e}
          helperText={ht ? ht : e ? validFielddHelper(1) : null}
          {...params}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: e ? 'red' : null,
              },
            },
            '& .MuiFormHelperText-root': {
              color: e ? 'red' : null,
            },
            '& .MuiFormLabel-root': {
              color: e ? 'red' : null,
            },
          }}
        />
      )}
    />
  </LocalizationProvider>
);

export const FechaField = ({ l, v, on, ht, e, isNull }) => (
  <LocalizationProvider adapterLocale={'es'} dateAdapter={AdapterDayjs}>
    <DatePicker
      label={l ?? 'Fecha'}
      value={isNull ? null : v ?? dateformat(null, {})}
      openTo="day"
      onChange={on}
      views={['day']}
      renderInput={(params) => (
        <TextField
          fullWidth
          size="small"
          required
          helperText={ht ?? e ? validFielddHelper(1) : null}
          {...params}
        />
      )}
    />
  </LocalizationProvider>
);

export const TieneInternetField = ({ v, on }) => (
  <FormControlLabel
    control={
      <Switch checked={v ?? false} onChange={on} name={CONST.TIENE_INTERNET} />
    }
    label="¿Tiene Internet?"
    labelPlacement="end"
  />
);

export const DireccionField = ({ v, on, ht, e }) => (
  <TextField
    fullWidth
    id={CONST.DIRECCION}
    label="Dirección"
    multiline
    maxRows={4}
    value={v}
    onChange={on}
    error={e}
    helperText={ht ?? e ? validFielddHelper(1) : null}
    size="small"
    required
  />
);

export const NumeroField = ({ v, on, ht, e }) => (
  <TextField
    fullWidth
    size="small"
    id={CONST.NUMERO}
    required
    label="Número"
    value={v}
    onChange={on}
    error={e}
    helperText={ht ?? e ? validFielddHelper(1) : null}
  />
);

export const CorreoField = ({
  v,
  on,
  ht,
  e,
  dis,
  autoComplete,
  errorEmail,
  required = true,
}) => (
  <TextField
    fullWidth
    size="small"
    id={CONST.CORREO}
    label="Correo Electrónico"
    type="email"
    required={required}
    value={v}
    onChange={on}
    autoComplete={autoComplete}
    error={e}
    disabled={dis}
    helperText={ht ?? e ? validFielddHelper(errorEmail ? 2 : 1) : null}
  />
);

export const UsernameField = ({ v, on, ht, e }) => (
  <TextField
    fullWidth
    size="small"
    id={CONST.USERNAME}
    label="Usuario"
    required
    value={v}
    onChange={on}
    error={e}
    helperText={ht ?? e ? validFielddHelper(1) : null}
  />
);
